<template>
    <b-navbar type="light" toggleable="xl" fixed="top" v-b-scrollspy:nav-scroller class="header-area"
        :class="{ 'is-sticky': scrolled }">
        <div class="container-fluid container-fluid--cp-150">
            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
            <b-navbar-brand class="navbar-brand" to="/">
                <img :src='"../assets/img/logo/coloured-logo-dark-text.png"' alt="logo" />
            </b-navbar-brand>
            <b-btn-group class="header-config-wrapper">
                <!-- <b-btn class="header-config" @click="toggleClass('addClass', 'active')"><i class="far fa-search"></i></b-btn> -->
                <!-- <b-link class="ht-btn ht-btn--outline hire-btn d-none d-xl-block">Login</b-link> -->
            </b-btn-group>
            <b-collapse class="default-nav justify-content-center" is-nav id="nav_collapse">
                <b-navbar-nav class="navbar-nav main-menu">
                    <!-- <b-nav-item to="/"><span>LANDING</span></b-nav-item> -->
                    <b-nav-item to="/" class="scroll"
                        @click="sendGAClickNavEvent('Home')"><span>HOME</span></b-nav-item>
                    <b-nav-item-dropdown class="scroll" text="ABOUT US" @click="sendGAClickNavEvent('About Us')">
                        <b-dropdown-item to="/#about" class="scroll"
                            @click="sendGAClickNavEvent('About Us: What is RadiXplore')">What is
                            RadiXplore</b-dropdown-item>
                        <b-dropdown-item to="/#founders" class="scroll"
                            @click="sendGAClickNavEvent('About Us: The Founders')">The Founders</b-dropdown-item>
                        <b-dropdown-item to="/values"
                            @click="sendGAClickNavEvent('About Us: Mission and Values')">Mission and
                            Values</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item to="/#products" class="scroll" @click="sendGAClickNavEvent('Home')">
                        <span>PRODUCTS</span>
                    </b-nav-item> -->

                    <!-- <b-nav-item-dropdown class="scroll" text="PRODUCTS" @click="sendGAClickNavEvent('Products', true)">
                        <div class="position-relative" @click="handleFeatureShow('features')">
                            <div class="scroll px-3 py-1 pointer d-flex justify-content-between align-items-center"
                                @click="sendGAClickNavEvent('Products: Features')">
                                Features
                                <BIconCaretRightFill class="ml-2 mb-1" font-scale="0.6" v-if="hover.features" />
                            </div>
                            <div class="floating-menu" v-if="hover.features">
                                <b-dropdown-item to="/?tabId=0#tabs">RadixSearch</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=1#tabs">RadixSuggest</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=2#tabs">RadixEye</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=3#tabs">RadixGPT</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=4#tabs">RadixFinance</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=5#tabs">Heatmap</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=6#tabs">Metadata Integration</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=7#tabs">RadixExtract</b-dropdown-item>
                                <b-dropdown-item to="/?tabId=8#tabs">Geospatial Visualizer</b-dropdown-item>
                            </div>
                        </div>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown class="scroll" text="SOLUTONS" @click="sendGAClickNavEvent('Solutions', true)">
                        <div class="position-relative" @click="handleFeatureShow('byRole')">
                            <div class="scroll px-3 py-1 pointer d-flex justify-content-between align-items-center"
                                @click="sendGAClickNavEvent('Solutions: By Role')">
                                By Role
                                <BIconCaretRightFill class="ml-2 mb-1" font-scale="0.6" v-if="hover.byRole" />
                            </div>
                            <div class="floating-menu" v-if="hover.byRole">
                                <b-dropdown-item>Data Manager</b-dropdown-item>
                                <b-dropdown-item>Project Generation</b-dropdown-item>
                                <b-dropdown-item>Exploration</b-dropdown-item>
                                <b-dropdown-item>Business Development</b-dropdown-item>
                            </div>
                        </div>
                        <div class="position-relative" @click="handleFeatureShow('byIndustry')">
                            <div class="scroll px-3 py-1 pointer d-flex justify-content-between align-items-center"
                                @click="sendGAClickNavEvent('Solutions: By Industry')">
                                By Industry
                                <BIconCaretRightFill class="ml-2 mb-1" font-scale="0.6" v-if="hover.byIndustry" />
                            </div>
                            <div class="floating-menu" v-if="hover.byIndustry">
                                <b-dropdown-item>Oil and Gas</b-dropdown-item>
                                <b-dropdown-item>Minerals</b-dropdown-item>
                                <b-dropdown-item>Natural Hydrogen</b-dropdown-item>
                                <b-dropdown-item>CCUS</b-dropdown-item>
                                <b-dropdown-item>Geotechnical</b-dropdown-item>
                            </div>
                        </div>
                    </b-nav-item-dropdown> -->

                    <b-nav-item to="/our-pricing" class="scroll"
                        @click="sendGAClickNavEvent('Pricing')"><span>PRICING</span></b-nav-item>
                    <b-nav-item to="/#features" class="scroll" @click="sendGAClickNavEvent('Why RadiXplore')"><span>WHY
                            RADIXPLORE</span></b-nav-item>
                    <b-nav-item :to="{ name: 'Blog' }" exact active-class="active-link"
                        @click="sendGAClickNavEvent('Blog')"><span>BLOG</span></b-nav-item>
                    <!-- <b-nav-item to="/usecases" class="scroll"><span>USE CASES</span></b-nav-item> -->
                    <!-- <b-nav-item href="#testimonials" class="scroll"><span>TESTIMONIALS</span></b-nav-item> -->
                </b-navbar-nav>
                <b-navbar-nav class="ml-0 ml-md-5">
                    <b-dropdown class="scroll log-in-btn align-items-center m-3" text="LOGIN"
                        @click="sendGAClickNavEvent('Login', false, $event)">
                        <b-dropdown-item href="https://app.radixplore.com" target="_blank"
                            @click="sendGAClickLoginEvent('RadiXplore Mining', false, $event)">RadiXplore
                            Mining</b-dropdown-item>
                        <b-dropdown-item href="https://petroleum.radixplore.com" target="_blank"
                            @click="sendGAClickLoginEvent('RadiXplore Petroleum', false, $event)">RadiXplore
                            Petroleum</b-dropdown-item>
                    </b-dropdown>

                    <div class="d-flex align-items-center" @click="sendGAClickLoginEvent('Sign Up', false, $event)">
                        <b-button class="w-100 m-3 sign-up-btn" @click="handleSignUp">SIGN UP</b-button>
                    </div>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
import { BIconCaretRightFill } from 'bootstrap-vue'

export default {
    name: "Navbar",
    components: [BIconCaretRightFill],
    data() {
        return {
            load: false,
            limitPosition: 200,
            scrolled: true, // hard code to true for now, no time to manage dark and light background changes
            lastPosition: 500,
            hover: {
                features: false,
                byRole: false,
                byIndustry: false,
                byCompany: false,
                byDataType: false,
            },
        };
    },
    mounted() {
        (function () {
            scrollTo();
        })();

        function scrollTo() {
            const links = document.querySelectorAll(".scroll > a");
            links.forEach((each) => (each.onclick = scrollAnchors));
        }

        function scrollAnchors(e, respond = null) {
            const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);
            e.preventDefault();
            var targetID = respond
                ? respond.getAttribute("href")
                : this.getAttribute("href");
            const targetAnchor = document.querySelector(targetID);
            if (!targetAnchor) return;
            const originalTop = distanceToTop(targetAnchor);
            window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
            const checkIfDone = setInterval(function () {
                const atBottom =
                    window.innerHeight + window.pageYOffset >=
                    document.body.offsetHeight - 190;
                if (distanceToTop(targetAnchor) === 0 || atBottom) {
                    targetAnchor.tabIndex = "-1";
                    targetAnchor.focus();
                    clearInterval(checkIfDone);
                }
            }, 800);
        }
    },
    methods: {
        // sticky menu script
        handleScroll() {
            if (
                this.lastPosition < window.scrollY &&
                this.limitPosition < window.scrollY
            ) {
                this.scrolled = true;
                // move up!
            }
            if (this.lastPosition > window.scrollY) {
                this.scrolled = true;
                // move down
            }
            this.lastPosition = window.scrollY;
            this.scrolled = window.scrollY > 50;
        },

        // offcanvas searchbox
        toggleClass(addRemoveClass, className) {
            const el = document.querySelector("#search-overlay");
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },

        async handleSignUp() {
            this.$router.push("/signup");
        },

        sendGAClickLoginEvent(serviceName) {
            this.$gtag.event("user_login", {
                event_category: "Login",
                event_label: serviceName,
                value: 1,
            });
        },

        sendGAClickNavEvent(navSection, resetHoverState, event) {
            this.$gtag.event("navigation", {
                event_category: "Navigation",
                event_label: navSection,
                value: 1,
            });

            if (resetHoverState) {
                this.hover.features = false;
                this.hover.byRole = false;
                this.hover.byIndustry = false;
                this.hover.byCompany = false;
                this.hover.byDataType = false;
            }

            if (event) {
                event.stopPropagation();
            }
        },

        handleFeatureShow(section) {
            if (this.hover[section]) {
                this.hover[section] = false;
            } else {
                this.hover.features = false;
                this.hover.byRole = false;
                this.hover.byIndustry = false;
                this.hover.byCompany = false;
                this.hover.byDataType = false;
                this.hover[section] = true;
            }
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>
<style lang="scss">
@import "../assets/scss/variabls.scss";

.pointer {
    cursor: pointer !important
}

.floating-menu {
    position: absolute;
    top: -8px;
    left: 165px;
    /* Position it to the right of the hover box */
    background-color: white;
    padding: 10px 0;
    border-radius: 0.2rem;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    /* Ensure it appears above other elements */
    white-space: nowrap;
    /* Prevent the text from wrapping */
}

.log-in-btn {
    .btn {
        background-color: $theme-color--default !important;
        height: 43px;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }
}

.sign-up-btn {
    border: solid 3px $theme-color--default !important;
    color: $theme-color--default;
    background-color: transparent;
    height: 43px;
    padding: 0 20px;
    padding-bottom: 1rem;
    font-size: 16px;
    line-height: 41px;
    align-items: center;
}

.sign-up-btn:hover {
    color: $theme-color--default;
}

.active-link {
    color: #3a4eca !important;
}
</style>
